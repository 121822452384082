<template>
    <section>
        <div>
            <header class="d-flex justify-content-center align-items-center header-background-image">
                <div class="d-flex flex-column justify-content-center align-items-center header-text">
                    <h1>Your One-Stop</h1>
                    <h1>Platform To Order</h1>
                    <h1 class="underline mt-2" style="font-weight: 900;font-family: Raleway, sans-serif;">GCMS notes
                    </h1>
                    <p class="mb-4 mt-2">Secure, reliable and trustworthy</p>
                    <router-link class="btn btn-danger btn-lg rounded-0 px-4" :to="{ name: 'order' }">Order
                        Now</router-link>
                </div>
            </header>
        </div>
        <div>
            <div class="container custom-container" style="color: #24285B;">
                <h2 class="text-capitalize text-center">Used by thousands of people</h2>
                <h2 class="text-capitalize text-center">around the world.</h2>
                <div class="row gy-3 gx-lg-5 gy-lg-5 row-cols-1 row-cols-lg-3 mt-lg-5 mt-2">
                    <div class="col"><img class="mb-3 icon" src="@/assets/img/mdi_clock-fast.svg">
                        <div>
                            <h5 class="description-title">It is fast &amp; efficient</h5>
                            <p class="description-text" style="text-align: justify;">With a focus on speed and
                                effectiveness, our team ensures that tasks are completed promptly and with high
                                efficiency. We Prioritize process optimization for reduced turnaround times and
                                accuracy.</p>
                        </div>
                    </div>
                    <div class="col"><img class="mb-3 icon" src="@/assets/img/bi_cash-stack.svg">
                        <div>
                            <h5 class="description-title">Competitive prices</h5>
                            <p class="description-text" style="text-align: justify;">Our service offers competitive
                                pricing, cost-effectiveness, transparency, and optimization of processes for accuracy
                                and quality. Our transparent pricing model ensures you achieve goals while staying
                                within your budget.</p>
                        </div>
                    </div>
                    <div class="col"><img class="mb-3 icon" src="@/assets/img/currency-exchange-icon.svg">
                        <div>
                            <h5 class="description-title">Pay in multiple currencies</h5>
                            <p class="description-text" style="text-align: justify;">Our service offers multiple
                                currency payment convenience for international transactions, we offer the flexibility to
                                accept payments in various currencies.</p>
                        </div>
                    </div>
                    <div class="col"><img class="mb-3 icon" src="@/assets/img/mdi_track-changes.svg">
                        <div>
                            <h5 class="description-title">Online tracking</h5>
                            <p class="description-text" style="text-align: justify;">Easily track your orders online as
                                our service offers convenient online tracking for tracking orders and shipments,
                                allowing easy monitoring and management from anywhere, anytime.</p>
                        </div>
                    </div>
                    <div class="col"><img class="mb-3 icon"
                            src="@/assets/img/streamline_interface-help-customer-support-1-customer-headset-help-microphone-phone-support.svg">
                        <div>
                            <h5 class="description-title">Quick customer response</h5>
                            <p class="description-text" style="text-align: justify;">Experience prompt and responsive
                                customer support with our service. Our dedicated team is committed to providing timely
                                assistance and resolving inquiries efficiently.</p>
                        </div>
                    </div>
                    <div class="col"><img class="mb-3 icon" src="@/assets/img/mdi_security-account-outline.svg">
                        <div>
                            <h5 class="description-title">Data security and privacy</h5>
                            <p class="description-text" style="text-align: justify;">Your data security and privacy are
                                our top priorities. We adhere to industry best practices and compliance standards to
                                safeguard your privacy.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="d-flex justify-content-center align-items-center mid-background-image">
            <div style="color: #ffffff;width: 60%;">
                <h2 class="mb-3" style="text-align: center;"><img class="me-2 icon"
                        src="@/assets/img/line.svg"><em>About US</em><img class="ms-2 icon" src="@/assets/img/line.svg">
                </h2>
                <p style="text-align: center;"><span class="w"><em>W</em></span><em>e are a registered Canadian company
                        that provides GCMS notes
                        services in a secure, reliable, and trustworthy manner. Our team of
                        experienced professionals has in-depth knowledge of the GCMS systems
                        and is dedicated to helping you achieve your goals in the visa
                        process. We appreciate your choice to use our services and hope that
                        they make the process as smooth as possible for you.</em></p>
            </div>
        </div>
        <div>
            <div class="container custom-container" style="color: #24285B;">
                <h2 class="text-capitalize text-center">Our Services</h2>
                <div class="row gy-2 gy-lg-5 row-cols-1 row-cols-lg-2 justify-content-evenly mt-5">
                    <div class="col p-3 p-lg-5 custom-shadow service">
                        <h5><strong>GCMS Notes</strong></h5>
                        <p>Notes are typically delivered within 30 - 45 days</p>
                        <hr style="color: #24285B;">
                        <p>Includes:</p>
                        <p style="height: 130px;">Electronic GCMS notes Detailed record of visa application; includes
                            full case history and officers’ notes Can be requested from IRCC or both IRCC & CBSA
                            (additional fee applies). By default request is sent to IRCC only </p>
                        <div>
                            <router-link class="btn btn-primary rounded-0 mt-4" role="button" style="width: 100%;"
                                :to="{ name: 'order' }">Order now<img class="order-arrow"
                                    src="@/assets/img/material-symbols_arrow-forward.svg"></router-link>
                        </div>
                    </div>
                    <div class="col p-3 p-lg-5 custom-shadow service">
                        <h5><strong>GCMS File</strong></h5>
                        <p>Notes are typically delivered within 30 - 45 days</p>
                        <hr style="color: #24285B;">
                        <p>Includes:</p>
                        <p>Electronic GCMS Notes and copies of all documents, detailed record of visa application;
                            includes full case history and officers notes and copies of all forms and supporting
                            documents sent to/from IRCC</p>
                        <div>
                            <router-link class="btn btn-primary rounded-0 mt-4" role="button" style="width: 100%;"
                                :to="{ name: 'order' }">Order now<img class="order-arrow"
                                    src="@/assets/img/material-symbols_arrow-forward.svg"></router-link>
                        </div>
                    </div>
                    <div class="col p-3 p-lg-5 custom-shadow service">
                        <h5><strong>CBSA Notes</strong></h5>
                        <p>Notes are typically delivered within 30 - 45 days</p>
                        <hr style="color: #24285B;">
                        <p>Includes:</p>
                        <p class="service-description">Electronic notes from CBSA Notes to check status of visa
                            application and security screening done by CBSA. Border crossing details; useful when
                            applying for PR card renewal or filing for citizenship application Requested from CBSA</p>
                        <div>
                            <router-link class="btn btn-primary rounded-0 mt-4" role="button" style="width: 100%;"
                                :to="{ name: 'order' }">Order now<img class="order-arrow"
                                    src="@/assets/img/material-symbols_arrow-forward.svg"></router-link>
                        </div>
                    </div>
                    <div class="col p-3 p-lg-5 custom-shadow service">
                        <h5><strong>CSIS Notes</strong></h5>
                        <p>Notes are typically delivered within 30 - 45 days</p>
                        <hr style="color: #24285B;">
                        <p>Includes:</p>
                        <p>Electronic notes from CSIS Notes to check status of visa application and security screening
                            done by CSIS. Border crossing details; useful when applying for PR card renewal or filing
                            for citizenship application</p>
                        <div>
                            <router-link class="btn btn-primary rounded-0 mt-4" role="button" style="width: 100%;"
                                :to="{ name: 'order' }">Order now<img class="order-arrow"
                                    src="@/assets/img/material-symbols_arrow-forward.svg"></router-link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div>
            <div class="custom-container" style="background-color: #24285B;color: #ffffff;">
                <h2 class="text-center">We are here to serve you</h2>
                <p class="text-center">we are known for giving the best</p>
                <div class="row gy-3 gx-lg-5 gy-lg-5 row-cols-1 row-cols-lg-3 justify-content-center mt-2">
                    <div class="col text-center"><img class="icon" src="@/assets/img/mdi_user-group.svg">
                        <div>
                            <h2>1000 +</h2>
                            <p class="text-center">Happy clients</p>
                        </div>
                    </div>
                    <div class="col text-center"><img class="icon" src="@/assets/img/bx_world.svg">
                        <div>
                            <h2>20 +</h2>
                            <p class="text-center">Countries served</p>
                        </div>
                    </div>
                    <div class="col text-center"><img class="icon"
                            src="@/assets/img/material-symbols_clinical-notes-rounded.svg">
                        <div>
                            <h2>800 +</h2>
                            <p class="text-center">GCMS notes delivered</p>
                        </div>
                    </div>
                </div>
                <div class="text-center mt-2 mt-lg-5">
                    <router-link class="btn btn-light rounded-0 px-4 py-2" :to="{ name: 'order' }">Get
                        started</router-link>
                </div>
            </div>
        </div>
    </section>

</template>

<script>
export default {
    name: "HomeView",
    mounted() {
        document.title = "Home | GCMSONLINE";

    },
};
</script>


<style scoped>
.header-background-image {
    background-image: url('@/assets/img/gcmsonline-header.png');
    background-repeat: no-repeat;
    background-size: cover;
}

.mid-background-image {
    background: url("@/assets/img/flag.svg") no-repeat;
    height: 350px;
}

@media (max-width: 576px) {
    .mid-background-image {
        background: url("@/assets/img/flag.svg") center no-repeat;
        height: 350px;
    }
}
</style>
